import './App.css';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material/';
const theme = createTheme({
	palette: {
		primary: {
			main: '#97A3AE'
		},
		background: {
			default: '#97A3AE',
			paper: '#FFD600',

		}
	}
});
var loc = {};
var haversine = require("haversine-distance");


function failedPosition(error) {
	console.log(error);
}

function App() {
	const [flag, setFlag] = useState(false);
	const [text, setText] = useState("Join Waitlist");
	const [ready, setReady] = useState(false);

	function showPosition(position) {
		console.log(position);
		loc = { lat: position.coords.latitude, lng: position.coords.longitude };
		console.log(loc);
		setReady(true);
	}
	useEffect(() => {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(showPosition, failedPosition, { enableHighAccuracy: true, maximumAge: 0, timeout: 5000 });
	} else {
		console.log("Geolocation is not supported by this browser.");
	}
	}, []);
	useEffect(() => {

		console.log(loc);
		var point1 = { lat: 25.1789918, lng: 55.2416152 }

		//Second point in your haversine calculation
		var point2 = { lat: loc.lat, lng: loc.lng }
		console.log(point2);
		var haversine_m = haversine(point1, point2); //Results in meters (default)
		var haversine_km = haversine_m / 1000; //Results in kilometers

		console.log("distance (in meters): " + haversine_m + "m");
		console.log("distance (in kilometers): " + haversine_km + "km");
		if (haversine_km < 4) {
			setFlag(true);
		}
	}, [ready]);
	useEffect(() => {
		console.log(flag);
		if (flag) {
			setText("Join Waitlist");
		} else {
			setText("Get closer to join Waitlist");
		}
	}, [flag]);


	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<div className="App">
				<header className="App-header">

					<Box sx={{ width: 1, height: '100%' }}>

						<Grid container justifyContent="center" alignItems="center" spacing={2}>
							<Grid item xs={10} mb={4}>
								<img src="/sadeemlogocolor-removebg-preview.png" width={200} height={200} alt="logo" />
							</Grid>

							<Grid item xs={10}>
								<Button fullWidth href="http://www.sevenrooms.com/waitlist/sadeem" disabled={!flag} variant="contained" size="large">{text}</Button>
							</Grid>
							<Grid item xs={10}>
								<Button fullWidth variant="contained" href="https://order.chatfood.io/sadeemcafe/menus/sadeem" size="large">Menu</Button>
							</Grid>
							<Grid item xs={10}>
								<Button fullWidth variant="contained" href="https://order.chatfood.io/sadeemcafe/pickup/sadeem" size="large">Pickup</Button>
							</Grid>
							<Grid item xs={10}>
								<Button fullWidth variant="contained" href="https://order.chatfood.io/sadeemcafe/delivery/sadeem" size="large">Delivery</Button>
							</Grid>
							<Grid item xs={10}>
								<Button fullWidth variant="contained" href="https://goo.gl/maps/rtjd6TrstRUNFsQm9" size="large">Location</Button>
							</Grid>
							<Grid item xs={10}>
								<IconButton color="primary" href="https://www.instagram.com/sadeemdubai/?hl=en" aria-label="add to shopping cart">
									<InstagramIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Box>
				</header>

			</div>
		</ThemeProvider>

	);
}

export default App;
